import React from 'react';
import PropTypes from 'prop-types';
import { Full as FullLogo } from '../linkedLogo';
import NavAnchor from './navAnchor';
import { navigate } from 'gatsby';
import { Box, Heading } from 'grommet';
import { JOIN_LINK } from '../button/joinButton'


const NAV_ITEMS = [
  {
    displayName: 'Bio',
    link: '/bio',
  },
  {
    displayName: 'Method',
    link: '/method',
  },
  {
    displayName: 'Pricing',
    link: '/pricing',
  },
  {
    displayName: 'Join',
    link: JOIN_LINK,
  },
];

const renderNavItems = (select = 'last') => {
  let items = [];
  let start = select === 'first' ? 0 : NAV_ITEMS.length / 2;
  let end = select === 'first' ? NAV_ITEMS.length / 2 : NAV_ITEMS.length;

  for (let i = start; i < end; i++) {
    items.push(
      <Box key={i} pad={{ horizontal: '5vw' }} as="li">
        <NavAnchor
          color="dark-1"
          label={
            <Heading level={4} size="small">
              {NAV_ITEMS[i].displayName}
            </Heading>
          }
          onClick={() => navigate(NAV_ITEMS[i].link)}
        />
      </Box>
    );
  }
  return items;
};

const DesktopHeader = ({ fixed }) => (
  <Box
    id="desktopHeader"
    fill="horizontal"
    background="light-1"
    direction="row"
    justify="center"
    align="center"
    pad={{ vertical: '10px' }}
    width="100%"
  >
    <nav>
      <Box direction="row" align="center" justify="center" as="ul">
        {renderNavItems('first')}
        <Box pad={{ horizontal: '0' }} as="li">
          <FullLogo />
        </Box>
        {renderNavItems('last')}
      </Box>
    </nav>
  </Box>
);

DesktopHeader.propTypes = {
  fixed: PropTypes.bool,
};

export default DesktopHeader;
