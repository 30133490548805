import React from 'react';
import Button from './button';
// import TrackedButton from '../AnalyticsEvents/TrackedButton';
import withAnalyticsEvent from '../AnalyticsEvents/withAnalyticsEvent';

export const JOIN_LINK="mailto:hawksrunning215@gmail.com";

const JoinButton = props => {
  return (
    <Button
      primary
      label="join"
      href={JOIN_LINK}
      {...props}
    />
  );
};

export default withAnalyticsEvent(JoinButton, {
  eventAction: 'join',
  eventCategory: 'click',
  eventLabel: 'join_button',
});
